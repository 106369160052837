export function darkmode() {
	const body = document.body;
	const darkModeToggle = document.getElementById('dark-button');

	if (localStorage.getItem('darkMode') === 'enabled') {
		body.classList.add('dark');
	}

	darkModeToggle.addEventListener('click', () => {
		console.log("Modo Oscuro");
		if (body.classList.contains('dark')) {
			body.classList.remove('dark');
			localStorage.setItem('darkMode', 'disabled');
		} else {
			body.classList.add('dark');
			localStorage.setItem('darkMode', 'enabled'); // Corregido aquí
		}
	});
}
