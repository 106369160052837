export function accesibility() {

    $("#btn-search-item").on('click', function () {
        let slug = $("#post_slug").val();
        let collection = $("#collection_id").val();
        let search = $("#filter_general").val();
        window.location.href = "/" + slug + (slug == 'coleccion' ? '/' + collection : '') + "/?busqueda=" + search;
    });

    $("#btn-search-publication-date").on('click', function () {
        let slug = $("#post_slug").val();
        let collection = $("#collection_id").val();
        let year = $("#filter_anio").val();
        let month = $("#filter_month").val();
        let date = year + '-' + month;
        window.location.href = "/" + slug + (slug == 'fecha-de-publicacion' ? '/' + collection : '') + "/?fecha=" + date;
    });

    $("#btn-copiar-cita").on('click', function () {
        var textToCopy = $("#quote").text();
        var tempInput = $('<input>').val($.trim(textToCopy)).appendTo('body').select();
        document.execCommand('copy');
        tempInput.remove();

        $(this).text("¡Copiado al portapapeles!")
        setTimeout(() => {
            $(this).text("Copiar cita bibliográfica");
        }, "3000");
    });
    /*
        $("#btn-search-item-by-property").on('click', function () {
            let slug = $("#post_slug").val();
            let collection = $("#collection_id").val();
            let busqueda = $("#filter_general").val();
            window.location.href = "/" + slug + "/" + collection + "?texto=" + busqueda;
        });
    */

    $('.descarga-archivo-item').on('click', function() {
        console.log("descargando archivo...");
        let collection = $(this).data('collection');
        let item = $(this).data('item');
        dataLayer.push({'collection': collection, 'item': item});
    });
}